
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      comment: ""
    };
  },
  props: {
    setup: {
      type: Object,
      require
    }
  },
  methods: {
    changeDocumentStatus() {
      this.$emit("changeDocumentStatus", this.comment);
      this.comment = "";
    }
  },
});
