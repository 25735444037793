<template>
  <div class="table-responsive">
    <table
      class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
    >
      <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-120px text-start">Actions</th>
          <th class="min-w-100px">Type</th>
          <th class="min-w-100px ">Document (Front)</th>
          <th class="min-w-100px ">Document (Back)</th>
          <th class="min-w-100px ">User</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="document in tableData" :key="document.id">
          <td class="text-start">
            <div class="btn-group btn-group-sm pb-2 pt-1" role="group">
              <button
                @click="openChangeDocumentStatusModal(document, 'APPROVE')"
                data-bs-target="#changeDocumentStatusModal"
                data-bs-toggle="modal"
                class="btn btn-sm btn-success"
              >
                Approve
              </button>
              <button
                @click="openChangeDocumentStatusModal(document, 'DECLINE')"
                data-bs-target="#changeDocumentStatusModal"
                data-bs-toggle="modal"
                class="btn btn-sm btn-danger"
              >
                Decline
              </button>
            </div>
          </td>
          <td>
            <span class="badge rounded-pill bg-primary">{{
              document.type.name
            }}</span>
          </td>
          <td>
            <a
              target="_blank"
              :href="document.file_link"
              class="text-dark fw-bolder text-hover-primary fs-6"
              >{{ document.name }}</a
            >
          </td>
          <td>
            <span
              v-html="document.file_back_link"
              class="text-dark fw-bolder text-hover-primary fs-6"
            ></span>
          </td>
          <td>
            <router-link
              :to="'/users/' + document.created_by.id"
              class="text-dark fw-bolder text-hover-primary fs-6"
              >{{ document.created_by.name }}</router-link
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DocumentsDataTable",
  props: {
    tableData: Object
  },
  methods: {
    openChangeDocumentStatusModal(document, action) {
      this.$emit("modalButtonIsClicked", {
        selectedDocument: document,
        action
      });
    }
  }
});
</script>
