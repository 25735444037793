<template>
  <!--begin:approvalsIndex-->
  <div class="row gy-5 g-xl-8">
    <ApprovalsList
      widget-classes="card-xxl-stretch mb-5 mb-xl-8"
    ></ApprovalsList>
  </div>
  <!--end::approvalsIndex-->
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import ApprovalsList from "@/components/approvals/ApprovalsList.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "ApprovalsView",
  components: {
    ApprovalsList
  },
  mounted() {
    setCurrentPageTitle("Approvals");
  },
});
</script>
