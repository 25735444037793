<template>
  <div class="card" :class="widgetClasses">
    <!--begin::Header-->
    <ChangeDocumentStatusModal
      v-show="isThereAModalActive"
      :setup="modalSetup"
      @changeDocumentStatus="handleChangeDocumentStatus"
    ></ChangeDocumentStatusModal>
    <div class="card mb-5 mb-xl-8">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">Approvals</span>

          <span class="text-muted mt-1 fw-bold fs-7">List of Approvals</span>
        </h3>
      </div>
      <div class="card-body py-3">
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <el-empty
            v-else-if="approvalsList.length == 0"
            :description="
          'There are no new applicants to approve.'
        "
        ></el-empty>
        <ApprovalsDataTable @modalButtonIsClicked="openChangeDocumentStatusModal" :tableData="approvalsList" v-else></ApprovalsDataTable>
      </div>
      <Pagination
          :currentPage="pagination.current_page"
          :totalPages="pagination.last_page"
          @changeCurrentPage="changeSearchByPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import ChangeDocumentStatusModal from "@/components/general/ChangeDocumentStatusModal.vue";
import ApprovalsDataTable from "@/components/approvals/ApprovalsDataTable.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";

export default defineComponent({
  name: "ApprovalsList",
  components: {
    ChangeDocumentStatusModal,
    ApprovalsDataTable,
    LoadingSpinner,
    Pagination
  },
  data() {
    return {
      currentlySelectedDocument: {},
      modalSetup: {},
      isThereAModalActive: false,
      pagination: {}
    }
  },
  props: {
    widgetClasses: String
  },
  methods: {
    ...mapActions("DocumentsModule", [
      "fetchApprovals",
      "changeDocumentStatus",
      "setPageNumber",
      "resetPageNumber",
      "resetState"
    ]),
    changeSearchByPage(pageNumber) {
      this.setPageNumber(pageNumber);
      this.fetchData();
    },
    closeModal() {
      this.isThereAModalActive = false;
      this.currentlySelectedDocument = {};
      this.modalSetup = {};
    },
    openChangeDocumentStatusModal(modalPayload) {
      this.modalSetup.action = modalPayload.action;
      this.currentlySelectedDocument = modalPayload.selectedDocument;
      this.isThereAModalActive = true;
    },
    handleChangeDocumentStatus(comment) {
      this.changeDocumentStatus({...this.currentlySelectedDocument, comment, status: this.modalSetup.action === 'APPROVE' ? 'Approved' : 'Declined'});
      this.isThereAModalActive = false;
      this.currentlySelectedDocument = {};
      this.modalSetup = {};
    }
  },
  computed: {
    ...mapGetters("DocumentsModule", [
      "approvalsList",
      "loading",
      "getPaginationData"
    ])
  },
  created() {
    this.fetchApprovals();
  },
  updated() {
    this.pagination = this.getPaginationData;
  },
  unmounted() {
    this.resetState();
  }
});
</script>

<style>
.spinner-size-increase {
  width: 3.5rem;
  height: 3.5rem;
}
</style>
